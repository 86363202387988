import React, { Component } from 'react'
import { Link } from 'gatsby'

//import Logo from '../assets/images/logo.inline.svg'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileNavOpen: false,
    }
  }

  toggleMobileNav = () => {
    document.body.classList.toggle('has-overlay')
    this.setState({ mobileNavOpen: !this.state.mobileNavOpen })
  }

  render() {
    const navItems = [
      { link: '/', title: 'Work' },
      { link: '/whoami/', title: 'Who Am I?' }
    ]

    return (
      <header>
        <div className="header-wrapper container">
          <div className="name">
            <Link
              to="./">
              Jennifer  UI // UX
            </Link>
          </div>
          <div className="header-nav">
            {navItems.map(navItem => {
              return (
                <Link
                  key={navItem.link}
                  to={navItem.link}
                  className={`header-nav__link ${navItem.class}`}
                  activeClassName="header-nav__link--active"
                >
                  {navItem.title}
                </Link>
              )
            })}
          </div>

        </div>
      </header>
    )
  }
}

export default Header
