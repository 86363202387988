import React from 'react'
//import { Link } from 'gatsby'

const Footer = ({ children }) => (
  <footer>
    	<div className="container">
        <div className="copyright">
          <p>Created with &lt;3 by Jennifer Patel &copy; <span className="currentYear">2020</span></p>
        </div>
      </div>
  </footer>
)

export default Footer
